import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// components
import HomeCarousel from "../components/HomeCarousel";
import HomeCarouselMobile from "../components/HomeCarouselMobile";
import SongDisplayList from "../components/ViewAll/SongDisplayList";
import PageNotFoundPage from "./PageNotFoundPage";

const Home = () => {
  const [songs, setSongs] = useState<Song[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecentSongs = async () => {
      const response = await fetch("/api/songs/recent/" + "7");
      const json = await response.json();

      if (response.ok) {
        setSongs(json);
      } else {
        setError(response.status + " - " + json.error);
      }
    };
    fetchRecentSongs();
  }, []);

  if (error) return <PageNotFoundPage msg={error} />;
  return (
    <div className="home">
      <Helmet>
        <title>home - dubu</title>
        <meta
          name="description"
          content="dubu Homepage: a database of your favorite KPOP idols, albums, songs, and credits."
        />
        <meta name="keywords" content="kpop, dubu, database, kpop credits" />
      </Helmet>
      <div className="carousel-container">
        <HomeCarousel />
      </div>
      <div className="carousel-container-mobile">
        <HomeCarouselMobile />
      </div>
      <div className="padding">
        {songs && songs[0] && (
          <div className="recent-songs-details">
            <h4 className="album-header">
              {" "}
              {songs && songs.length > 0 && "new song releases"}
            </h4>
            <div className="display-list">
              {songs.slice(0, 10).map((song) => (
                <SongDisplayList
                  key={song.melon_song_id}
                  song={song}
                  name={song.english_name}
                  picture_url={song.picture_url}
                  year={new Date(song.release_date)}
                  artist={[song.artist_english_name]}
                  artist_ids={[song.melon_artist_id]}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
