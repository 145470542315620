import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-bottom">
        <h6>
          Email:{" "}
          <Link to="mailto:contact.dubu@gmail.com">contact.dubu@gmail.com</Link>
        </h6>
        <h6>
          <Link to="/privacy-policy">privacy policy</Link>
        </h6>
        <h6>
          {new Date().getFullYear()} dubu: database of your favorite KPOP idols,
          albums, songs, and credits.
        </h6>
        <h6>
          found incorrect data? report errors <Link to="/feedback">here</Link>.
        </h6>
      </div>
    </footer>
  );
}

export default Footer;
