import React from "react";
import CreditDetailsByType from "./CreditDetailsByType";

const CreditDetails: React.FC<CreditDetailsProps> = ({
  artist_id,
  credits,
}) => {
  let featured = credits.filter((c) => c.credit_type.includes("F"));
  let written = credits.filter((c) => c.credit_type.includes("L"));
  let composed = credits.filter((c) => c.credit_type.includes("C"));
  let arranged = credits.filter((c) => c.credit_type.includes("A"));
  console.log("credit details", artist_id);

  return (
    <div className="credit-details">
      <br></br>
      {credits.length > 0 && <h4>song credits</h4>}
      {credits.length > 0 && (
        <div className="credited-details">
          {/* Featured */}
          {featured.length > 0 && (
            <CreditDetailsByType
              artist_id={artist_id}
              credits={featured}
              type="featured"
            />
          )}
          {/* Written */}
          {written.length > 0 && (
            <CreditDetailsByType
              artist_id={artist_id}
              credits={written}
              type="written"
            />
          )}
          {/* Composed */}
          {composed.length > 0 && (
            <CreditDetailsByType
              artist_id={artist_id}
              credits={composed}
              type="composed"
            />
          )}
          {/* Arranged */}
          {arranged.length > 0 && (
            <CreditDetailsByType
              artist_id={artist_id}
              credits={arranged}
              type="arranged"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CreditDetails;
