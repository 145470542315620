import { Link } from "react-router-dom";
import { TruncateName, encodeId } from "./HelperMethods";
import React from "react";

const MembershipDetails: React.FC<MembershipDetails> = ({
  memberships,
  type,
}) => {
  const type_modified = type
    .replace("past ", "")
    .replace("subunits", "members")
    .replace("production ", "");
  return (
    <div className={type + "-field"}>
      {memberships && memberships.length > 0 && <h4>{type.toUpperCase()}</h4>}
      <div className={"artist-" + type_modified}>
        {memberships.map((m) => (
          <div className={type_modified + "-card"} key={m.artist_id}>
            <Link
              onClick={() => {
                window.location.href = `/artist/${encodeId(m.artist_id)}`;
              }}
              state={{ id: m.artist_id }}
              to={""}
            >
              <p>
                {TruncateName(m.english_name)}
                <br></br>
                {TruncateName(m.korean_name)} <br></br>
              </p>
              <img
                className={type_modified + "-photo"}
                src={m.picture_url}
                alt={"artist photo of " + m.english_name}
                onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
                  event.currentTarget.src = "/dubuFace.jpg";
                  event.currentTarget.onerror = null;
                }}
              />
            </Link>
          </div>
        ))}
      </div>
      <br></br>
    </div>
  );
};

export default MembershipDetails;
