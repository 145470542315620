import { Link } from "react-router-dom";
import { encodeId } from "../HelperMethods";
import React from "react";

interface AllSongDetailsProps {
  songs: SongAllDetails[] | null;
  credits: boolean;
}

const AllSongDetails: React.FC<AllSongDetailsProps> = ({ songs, credits }) => {
  return (
    <div className="artist-songs">
      <table>
        <tbody>
          <tr>
            <th>#</th>
            <th>song</th>
            <th>album</th>
            <th>artist</th>
            <th>release date</th>
            {credits && <th>credit type</th>}
          </tr>

          {songs &&
            songs.map((song, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>
                    <Link
                      to={`/song/${encodeId(song.song_id)}`}
                      state={{ song_id: song.song_id, album_id: song.album_id }}
                    >
                      {song.is_title && "✰ "} {song.english_name}
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/album/${encodeId(song.album_id)}`}
                      state={{ id: song.album_id }}
                    >
                      {song.album_english_name}
                    </Link>
                  </td>
                  <td>
                    {song.artist_english_names &&
                      song.artist_ids &&
                      song.artist_english_names.map((name, idx) => (
                        <div key={idx}>
                          <Link
                            to={`/artist/${encodeId(song.artist_ids[idx])}`}
                            state={{ id: song.artist_ids[idx] }}
                          >
                            {name}
                          </Link>
                        </div>
                      ))}
                  </td>
                  <td>{song.release_date.toString().slice(0, 10)}</td>
                  {credits && <td>{song.credit_type}</td>}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default AllSongDetails;
