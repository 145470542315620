import { Link } from "react-router-dom";
import { encodeId } from "../HelperMethods";
import React from "react";

interface ArtistHeaderDetailsProps {
  artist: Artist;
  type: string;
}

const ArtistHeaderDetails: React.FC<ArtistHeaderDetailsProps> = ({
  artist,
  type,
}) => {
  return (
    <div className="artist-header">
      <div className="songs-page-header">
        <h1>all {type}</h1>
      </div>

      <Link
        to={`/artist/${encodeId(artist?.melon_artist_id)}`}
        state={{ id: artist.melon_artist_id }}
      >
        <h3>{artist && artist.english_name}</h3>
      </Link>

      <p>{artist && artist.korean_name}</p>
      <img
        className="all-songs-photo"
        src={artist && artist.picture_url}
        alt={
          "artist photo of " + artist.english_name + ", " + artist.korean_name
        }
        onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
          event.currentTarget.src = "/dubuFace.jpg";
          event.currentTarget.onerror = null;
        }}
      />
    </div>
  );
};

export default ArtistHeaderDetails;
