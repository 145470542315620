import { Link } from "react-router-dom";
import { encodeId } from "./HelperMethods";

const CreditListPerSong: React.FC<CreditList> = ({ credits }) => {
  return (
    <div className="credits-list">
      {credits.map((credit) => (
        <Link
          to={`/artist/${encodeId(credit.artist_id)}`}
          key={credit._id}
          state={{ id: credit.artist_id }}
        >
          {credit.english_name.length > 0 && (
            <div>
              {credit?.english_name}
              <br></br>
            </div>
          )}
          {credit.korean_name.length > 0 && (
            <div>
              {credit?.korean_name}
              <br></br>
            </div>
          )}

          <img
            className="credit-photo"
            src={credit.picture_url}
            alt={
              "artist photo of " +
              credit.english_name +
              ", " +
              credit.korean_name
            }
            onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
              event.currentTarget.src = "/dubuFace.jpg";
              event.currentTarget.onerror = null;
            }}
          />
        </Link>
      ))}
    </div>
  );
};

export default CreditListPerSong;
