import { Key } from "react";

export default function PhotoMethods(url: string, size = 461): string {
  if (!url) {
    return "";
  } else {
    return url.replace(
      /\/resize\/.*/,
      "/resize/" + size.toString() + "/quality/80/optimize"
    );
  }
}

export function TruncateName(name: string, max_char = 17): string {
  const ellipses = "...";
  if (!name) {
    return "";
  } else if (name.length < max_char + ellipses.length) {
    return name;
  }

  var trunc_name = "";
  const phrases = name.split(",");
  for (let j = 0; j < phrases.length; j++) {
    let words = phrases[j].split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i].length + trunc_name.length >= max_char) {
        return trunc_name.trim() + ellipses;
      } else {
        trunc_name = trunc_name + " " + words[i];
      }
    }
    if (phrases.length > 1 && j < phrases.length - 1) {
      trunc_name += ",";
    }
  }
  return "";
}

export function encodeId(id: number | string, new_base = 9): string {
  return (Number(id) + 1).toString(new_base);
}

export function decodeId(id: number | string, old_base = 9): number {
  return parseInt(String(id), old_base) - 1;
}
// export default { PhotoMethods, TruncateName };
