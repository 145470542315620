import Carousel from "react-bootstrap/Carousel";
import { SetStateAction, useState } from "react";
import { Link } from "react-router-dom";

import Image1 from "@images/20241014_jennie.jpeg";
import Image2 from "@images/20241014_karina.jpeg";
import Image3 from "@images/20240901_le_sserafim.jpeg";
import Image4 from "@images/20240907_meovv.jpg";

const data = [
  {
    image: Image1,
    caption: "jennie",
    description: "this that prettygirl mantra",
    album: 23758532,
  },
  {
    image: Image2,
    caption: "karina (aespa solos)",
    description: "spice it up, up, up",
    album: 23755140,
  },
  {
    image: Image3,
    caption: "le sserafim",
    description: "act like an angel & dress like crazy",
    album: 23708548,
  },
  {
    image: Image4,
    caption: "meovv",
    description: "we'll make 'em meow",
    album: 23718680,
  },
];

function HomeCarousel() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex: SetStateAction<number>) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      {data.map((slide, idx) => {
        return (
          <Carousel.Item key={idx}>
            <img
              className="carousel-home"
              loading="lazy"
              src={slide.image}
              alt="banner of the latest kpop releases"
            />
            <Link to={`/album/${slide.album}`}>
              <Carousel.Caption>
                <h3 style={{ "font-size": "300%" }}>{slide.caption}</h3>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
export default HomeCarousel;
