import { Link } from "react-router-dom";
import CreditListPerSong from "./CreditListPerSong";
import React, {
  Key,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useState,
} from "react";
// import { ReactComponent as ShareIcon } from "../images/arrow-up-from-bracket-solid.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { encodeId } from "./HelperMethods";

const SongDetails: React.FC<SongDetailsProps> = ({
  song,
  credits,
  artistinfo,
  albuminfo,
}) => {
  const album_id = song.melon_album_id;
  const featured = credits.filter((c) => c.credit_type.includes("F"));
  const written = credits.filter((c) => c.credit_type.includes("L"));
  const composed = credits.filter((c) => c.credit_type.includes("C"));
  const arranged = credits.filter((c) => c.credit_type.includes("A"));
  var picture_url;
  if (albuminfo.album_picture_url) {
    picture_url = albuminfo.album_picture_url.replace(
      /\/resize\/.*/,
      "/resize/461/quality/80/optimize"
    );
  } else {
    picture_url = "";
  }
  const tooltip = <Tooltip id="tooltip">copy song link</Tooltip>;
  const copied_tooltip = <Tooltip id="tooltip">copied song url!</Tooltip>;
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const copyLink = () => {
    // Your copy link logic here
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 1000); // Tooltip will disappear after 1 second
  };

  return (
    <div className="song-details">
      <div className="artist-photo-wrapper">
        <Link to={`/album/${encodeId(album_id)}`} state={{ id: album_id }}>
          <img
            className="main-photo"
            src={picture_url}
            alt={"album photo of " + albuminfo.album_english_name}
            onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
              event.currentTarget.src = "/dubuFace.jpg";
              event.currentTarget.onerror = null;
            }}
          />
        </Link>
      </div>
      <div className="song-right-card">
        <h5>
          ♬ TRACK {song.track_order} ON{" "}
          <Link to={`/album/${encodeId(album_id)}`} state={{ id: album_id }}>
            {albuminfo.album_english_name.toUpperCase()}
          </Link>{" "}
        </h5>

        <h1 className="main-text">
          <div>
            {song.is_title && "✰"}
            {song.is_explicit && "🅴 "} {song.english_name}
          </div>
          <OverlayTrigger
            placement="bottom"
            overlay={showCopiedTooltip ? copied_tooltip : tooltip}
            trigger="hover"
            rootClose
          >
            <button
              className="share-icon"
              onClick={copyLink}
              style={{ paddingLeft: "1em" }}
            >
              {/* <ShareIcon style={{ display: "flex", width: ".4em" }} /> */}
            </button>
          </OverlayTrigger>
        </h1>
        {song.korean_name != "" && <h4>{song.korean_name}</h4>}

        {artistinfo &&
          artistinfo.map((name) => (
            <h3 key={name.artist_id}>
              <Link
                to={`/artist/${encodeId(name.artist_id)}`}
                state={{ id: name.artist_id }}
              >
                {name.artist_id != -1 && name.artist_english_name}
              </Link>
              {name.artist_id == -1 && name.artist_english_name}
            </h3>
          ))}

        {featured.length > 0 && (
          <div className="featured-list">
            featuring
            {featured.map((credit) => (
              <div className="credits-list-inner" key={credit._id}>
                <Link
                  to={`/artist/${encodeId(credit.artist_id)}`}
                  state={{ id: credit.artist_id }}
                >
                  {credit.english_name}
                </Link>
              </div>
            ))}
            <br></br>
          </div>
        )}

        <h5>
          released on{" "}
          {albuminfo.release_date &&
            albuminfo.release_date.toString().slice(0, 10)}
        </h5>

        <div className="song-credits">
          <div className="lyricists">
            <p style={{ "font-size": "1.1em", "font-weight": "600" }}>LYRICS</p>

            {written.length > 0 && <CreditListPerSong credits={written} />}
          </div>
          <div className="composers">
            <p style={{ "font-size": "1.1em", "font-weight": "600" }}>
              COMPOSITION
            </p>
            {composed.length > 0 && <CreditListPerSong credits={composed} />}
          </div>
          {arranged.length > 0 && (
            <div className="arrangers">
              <p style={{ "font-size": "1.1em", "font-weight": "600" }}>
                {" "}
                ARRANGEMENT
              </p>
              <CreditListPerSong credits={arranged} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SongDetails;
