import { Link } from "react-router-dom";
import PhotoMethods from "./HelperMethods";
import { TruncateName } from "./HelperMethods";
import { encodeId } from "./HelperMethods";

const ArtistList: React.FC<ArtistList> = ({ artist, credits }) => {
  const id = artist.melon_artist_id;

  return (
    <div className="artist-list">
      <Link to={`/artist/${encodeId(id)}`} state={{ id: id }}>
        {artist.melon_artist_id != -1 && (
          <h4>
            {TruncateName(artist.english_name)}{" "}
            {credits && "(" + artist.num_songs + ")"}
          </h4>
        )}
        <img
          className="album-display-photo"
          src={PhotoMethods(artist.picture_url)}
          alt={
            "album photo of artist " +
            artist.english_name +
            ", " +
            artist.korean_name
          }
          onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
            event.currentTarget.src = "/dubuFace260.jpg";
            event.currentTarget.onerror = null;
          }}
        />
      </Link>
      {artist.melon_artist_id == -1 && <h4>name</h4>}
    </div>
  );
};

export default ArtistList;
