import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// import { Link, useLocation } from 'react-router-dom';

// components
import ArtistDetails from "../../components/ArtistDetails";
import AlbumList from "../../components/AlbumList";
import CreditDetails from "../../components/CreditDetails";
import SongDisplayList from "../../components/ViewAll/SongDisplayList";
import PageNotFoundPage from "../PageNotFoundPage";
import { decodeId } from "../../components/HelperMethods";

const ArtistPage = () => {
  const [artists, setArtist] = useState<Artist[] | null>(null);
  const [albums, setAlbums] = useState<Album[] | null>(null);
  const [credits, setCredits] = useState<Credits[] | null>(null);
  const [memberships, setMemberships] = useState<Membership[] | null>(null);
  const [titles, setTitles] = useState<Song[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  // TODO: figure out how to use state when using back button
  // let { state } = useLocation();
  let artist_id;
  artist_id = Number(window.location.href.split("/").slice(-1)[0]);
  artist_id = decodeId(artist_id);
  // console.log(artist_id.parseInt(9));
  // if (!state) {
  //   artist_id = Number(window.location.href.split('/').slice(-1)[0]);
  // } else {
  //   artist_id = state.id;
  // }

  useEffect(() => {
    const fetchArtist = async () => {
      const response = await fetch("/api/artists/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError("Artist does not exist");
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchAlbums = async () => {
      const response = await fetch("/api/albums/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setAlbums(json);
      } else if (response.status == 404 && !error) {
        setError("Album does not exist");
      } else if (response.status == 400 && !error) {
        setError("Invalid album");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchCredits = async () => {
      const response = await fetch("/api/credits/artist/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setCredits(json);
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchMemberships = async () => {
      const response = await fetch("/api/memberships/artist/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setMemberships(json);
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchTitles = async () => {
      const response = await fetch("/api/songs/artist/titles/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setTitles(json);
      } else if (response.status == 400 && !error) {
        setError("Invalid artist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    fetchArtist();
    fetchAlbums();
    fetchCredits();
    fetchMemberships();
    fetchTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="artist-page">
        <Helmet>
          {artists && <title>{artists[0].english_name} - dubu</title>}
          {!artists && <title>loading artist page - dubu</title>}
          {artists && (
            <meta
              name="keywords"
              content={
                "kpop, dubu, database, kpop credits, " + artists[0].english_name
              }
            />
          )}
          {!artists && (
            <meta
              name="keywords"
              content={"kpop, dubu, database, kpop credits"}
            />
          )}
          {artists && (
            <meta property="og:title" content={artists[0].english_name} />
          )}
          {artists && (
            <meta property="og:image" content={artists[0].picture_url} />
          )}
          <meta property="og:description" content="artist page" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image:width" content="500" />
          <meta property="og:image:height" content="500" />
          {artists && (
            <meta
              name="description"
              content={"Artist Page for " + artists[0].english_name}
            />
          )}
        </Helmet>
        {/* {(!artists || !albums || !credits) && } */}
        {artists && (
          <ArtistDetails
            key={artists[0]._id}
            artist={artists[0]}
            memberships={memberships}
          />
        )}
        <div className="incomplete-warning">
          <p>
            {artists &&
              !artists[0].is_idol &&
              "warning: artist data may be incomplete."}
          </p>
        </div>
        {artists && artists[0].spotify_artist_id && (
          <div className="spotify-details">
            <iframe
              className="spotify-artist-embed"
              style={{ "border-radius": "12px" }}
              src={
                "https://open.spotify.com/embed/artist/" +
                artists[0].spotify_artist_id +
                "?utm_source=generator"
              }
              // width="100%"
              // height="352px"
              // frameBorder="0"
              // allowfullscreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        )}

        <div className="artist-data">
          {titles && titles[0] && (
            <div className="artist-title-details">
              <br></br>
              <br></br>
              <p className="list-description">
                {titles && titles.length > 0 && (
                  <h4>
                    {/* <Link to="./songs" state={{ id: artist_id }}> */}
                    {artists && artists[0].is_group && "group"}
                    {artists && !artists[0].is_group && "solo"} title tracks
                    {/* </Link> */} ({titles.length})
                  </h4>
                )}
              </p>
              <div className="display-list">
                {titles.slice(0, 5).map((title) => (
                  <SongDisplayList
                    key={title.song_id}
                    song={title}
                    name={title.english_name}
                    picture_url={title.picture_url}
                    year={title.year}
                    artist={undefined}
                    artist_ids={undefined}
                  />
                ))}
                <br></br>
              </div>
              <Link className="see-all" to="./songs" state={{ id: artist_id }}>
                <u> see all songs</u>
              </Link>
            </div>
          )}
          {albums && albums[0] && (
            <div className="artist-title-details">
              <p className="list-description">
                <br></br>
                {albums.length > 0 && (
                  <h4>
                    {/* <Link to="./albums" state={{ id: artist_id }}> */}
                    {artists && artists[0].is_group && "group"}
                    {artists && !artists[0].is_group && "solo"} albums
                    {/* </Link> */} ({albums.length})
                  </h4>
                )}
              </p>
              <div className="display-list">
                {albums.slice(0, 5).map((album) => (
                  <AlbumList key={album._id} album={album} />
                ))}
                <br></br>
              </div>
              <Link className="see-all" to="./albums" state={{ id: artist_id }}>
                <u>see all albums</u>
              </Link>
            </div>
          )}

          {credits && (
            <div className="individual-credits">
              <CreditDetails artist_id={artist_id} credits={credits} />
            </div>
          )}

          <div className="all-info">
            {albums && albums.length > 0 && (
              <Link to="./albums" state={{ id: artist_id }}>
                view all albums
              </Link>
            )}
            {albums && albums.length > 0 && (
              <Link to="./songs" state={{ id: artist_id }}>
                view all songs
              </Link>
            )}
            {credits && credits.length > 0 && (
              <Link to="./credits" state={{ id: artist_id }}>
                view all credits
              </Link>
            )}

            {artists && artists[0].logged_at && (
              <i>
                data last updated on{" "}
                {artists[0].logged_at.toString().slice(0, 10)}.
              </i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistPage;
