import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

// components
import AlbumDetails from "../components/AlbumDetails";
import PageNotFoundPage from "./PageNotFoundPage";
import { decodeId } from "../components/HelperMethods";

const AlbumPage = () => {
  const [albums, setAlbum] = useState<Album[] | null>(null);
  const [songs, setSong] = useState<Song[] | null>(null);
  const [infos, setInfo] = useState<Info[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  let { state } = useLocation();
  let album_id;

  if (!state) {
    album_id = decodeId(Number(window.location.href.split("/").slice(-1)[0]));
  } else {
    album_id = state.id;
  }

  useEffect(() => {
    const fetchAlbum = async () => {
      const response = await fetch("/api/albums/album/" + album_id);
      const json = await response.json();

      if (response.ok) {
        setAlbum(json);
      } else if (response.status == 404 && !error) {
        setError("Album does not exist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchSong = async () => {
      const response = await fetch("/api/songs/album/" + album_id);
      const json = await response.json();

      if (response.ok) {
        setSong(json);
      } else if (response.status == 404 && !error) {
        setError("Songs does not exist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchExtraInfo = async () => {
      const response = await fetch("/api/albums/info/" + album_id);
      const json = await response.json();

      if (response.ok) {
        setInfo(json);
        // console.log(infos);
      } else if (response.status == 404 && !error) {
        setError("Album info does not exist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    fetchAlbum();
    fetchSong();
    fetchExtraInfo();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;
  // console.log(songs, infos);
  return (
    <div className="padding">
      <div className="album-page">
        <Helmet>
          {albums && infos && (
            <title>
              {albums[0].english_name} by{" "}
              {infos
                .map((a) => `${Object.values(a.english_name).join("")}`)
                .join(", ")}{" "}
              - dubu
            </title>
          )}
          {!albums && <title>loading album page - dubu</title>}
          {albums && (
            <meta
              name="keywords"
              content={
                "kpop, dubu, database, kpop credits, " + albums[0].english_name
              }
            />
          )}
          {albums && (
            <meta
              name="description"
              content={"Album page: " + albums[0].english_name}
            />
          )}
          {!albums && (
            <meta
              name="keywords"
              content={"kpop, dubu, database, kpop credits"}
            />
          )}
        </Helmet>
        {infos && albums && songs && (
          <AlbumDetails
            key={albums[0]._id}
            album={albums[0]}
            infos={infos}
            songs={songs}
          />
        )}
        <div className="all-info">
          {albums && albums[0].logged_at && (
            <i>
              data last updated on {albums[0].logged_at.toString().slice(0, 10)}
              .
            </i>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlbumPage;
