import { Helmet } from "react-helmet";
import PageNotFoundPage from "./PageNotFoundPage";
import SongDisplayList from "../components/ViewAll/SongDisplayList";
import { useState, useEffect } from "react";

const getOnThisDayPage = () => {
  const [songs, setSongs] = useState<SongAllDetails[] | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSongs = async () => {
      const response = await fetch("/api/songs/onthisday");
      const json = await response.json();

      if (response.ok) {
        setSongs(json);
      } else {
        setError(response.status + " - " + json.error);
      }
    };
    fetchSongs();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="about">
        <Helmet>
          <title>on this day - dubu</title>
          <meta name="keywords" content="kpop, dubu, database, kpop credits" />
          <meta name="description" content="dubu on this day" />
        </Helmet>
        <h1>
          {new Date().toLocaleString("en-us", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}{" "}
          - On this day...
        </h1>
        <br></br>
        <div className="display-list">
          {songs &&
            songs
              // .slice(0, 10)
              .map((song) => (
                <SongDisplayList
                  key={song.melon_song_id}
                  song={song}
                  name={song.english_name}
                  picture_url={song.picture_url}
                  year={new Date(song.release_date).getFullYear()}
                  artist={song.artist_english_names}
                  artist_ids={song.melon_artist_ids}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default getOnThisDayPage;
