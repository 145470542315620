import { Helmet } from "react-helmet";
import { useLocation, Link } from "react-router-dom";
import PhotoMethods from "../components/HelperMethods";
import { encodeId } from "../components/HelperMethods";

const SearchResultsPage = () => {
  let { state } = useLocation();
  const results: Result[] = state.results;
  var artist_results = results.filter((r: { type: string | string[] }) =>
    r.type.includes("IDOL")
  );
  artist_results = [
    ...artist_results,
    ...results.filter((r: { type: string | string[] }) =>
      r.type.includes("ARTIST")
    ),
  ];
  const album_results = results.filter((r) => r.type.includes("ALBUM"));
  const song_results = results.filter((r) => r.type.includes("SONG"));
  console.log("page", results);

  return (
    <div className="padding">
      <div className="not-found">
        <Helmet>
          <title>search results</title>
        </Helmet>
        <h1 className="main-text">Search Results</h1>
        <br></br>
        <div className="results-page">
          <div className="results-page-artists">
            <h3>artists</h3>
            {artist_results &&
              artist_results.map((result) => (
                <Link key={result.id} to={`/artist/${encodeId(result.id)}`}>
                  <p>
                    <img
                      className="search-photo"
                      src={PhotoMethods(result.picture_url)}
                      style={{
                        "max-width": "50px",
                        "padding-right": "1em",
                        "box-shadow": "0",
                      }}
                      onError={(
                        event: React.SyntheticEvent<HTMLImageElement>
                      ) => {
                        event.currentTarget.src = "/dubuFace.jpg";
                        event.currentTarget.onerror = null;
                      }}
                    ></img>
                    {result.name} {result.type == "IDOL" && " (idol)"}
                  </p>
                </Link>
              ))}
          </div>
          <br></br>
          <div className="results-page-albums">
            <h3>albums</h3>
            {album_results &&
              album_results.map((result) => (
                <Link key={result.id} to={`/album/${encodeId(result.id)}`}>
                  <p>
                    <img
                      className="search-photo"
                      src={PhotoMethods(result.picture_url)}
                      style={{
                        "max-width": "50px",
                        "padding-right": "1em",
                        "box-shadow": "0",
                      }}
                      onError={(
                        event: React.SyntheticEvent<HTMLImageElement>
                      ) => {
                        event.currentTarget.src = "/dubuFace.jpg";
                        event.currentTarget.onerror = null;
                      }}
                    ></img>
                    {result.name} by {result.artist_english_name}
                  </p>
                </Link>
              ))}
          </div>
          <br></br>
          <div className="results-page-songs">
            <h3>songs</h3>
            {song_results &&
              song_results.map((result) => (
                <Link key={result.id} to={`/song/${encodeId(result.id)}`}>
                  <p>
                    <img
                      className="search-photo"
                      src={PhotoMethods(result.picture_url)}
                      style={{
                        "max-width": "50px",
                        "padding-right": "1em",
                        "box-shadow": "0",
                      }}
                      onError={(
                        event: React.SyntheticEvent<HTMLImageElement>
                      ) => {
                        event.currentTarget.src = "/dubuFace.jpg";
                        event.currentTarget.onerror = null;
                      }}
                    ></img>
                    {result.name} by {result.artist_english_name}
                  </p>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsPage;
