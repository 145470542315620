import { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { encodeId } from "../components/HelperMethods";

// components
import PageNotFoundPage from "./PageNotFoundPage";

const ArtistDiscoveryListPage = () => {
  const [artists, setArtists] = useState<Artist[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  //   let { state } = useLocation();

  //   let is_idol = state.is_idol;
  //   let is_group = state.is_group;
  //   let gender = state?.gender;
  //   const is_idol = true;
  //   const is_group = true;

  useEffect(() => {
    const fetchArtists = async () => {
      const response = await fetch("/api/artists/");
      const json = await response.json();

      if (response.ok) {
        setArtists(json);
      } else {
        setError(response.status + " - " + json.error);
      }
    };
    fetchArtists();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;

  return (
    <div className="padding">
      <div className="artist-discovery">
        <Helmet>
          {artists && <title>list of kpop idols a to z - dubu</title>}
          {!artists && <title>loading artists - dubu</title>}
          <meta name="keywords" content="kpop, dubu, database, kpop credits" />
          <meta name="description" content="List of K-POP Idols from A to Z" />
        </Helmet>
        <div className="artists">
          <h1>discover artists (A-Z)</h1>
          <br></br>

          <h3>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: true }}
            >
              idol groups
            </Link>
          </h3>
          <h5>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: true, gender: "F" }}
            >
              GIRL GROUPS
            </Link>
          </h5>
          <div className="artist-discovery-list">
            {artists &&
              artists
                .filter((a) => a.is_idol == true && a.is_group == true)
                .filter((a) => a.gender == "F")
                .map((artist) => (
                  <div key={artist._id} className="artist-link">
                    <Link
                      key={artist._id}
                      to={`/artist/${encodeId(artist.melon_artist_id)}`}
                    >
                      <p key={artist._id}>{artist.english_name}</p>
                    </Link>
                  </div>
                ))}
          </div>

          <br></br>
          <h5>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: true, gender: "M" }}
            >
              BOY GROUPS
            </Link>
          </h5>
          <div className="artist-discovery-list">
            {artists &&
              artists
                .filter((a) => a.is_idol == true && a.is_group == true)
                .filter((a) => a.gender == "M")
                .map((artist) => (
                  <div key={artist._id} className="artist-link">
                    <Link
                      key={artist._id}
                      to={`/artist/${encodeId(artist.melon_artist_id)}`}
                    >
                      <p key={artist._id}>{artist.english_name}</p>
                    </Link>
                  </div>
                ))}
          </div>
          <br></br>
          <h5>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: true, gender: "C" }}
            >
              COED GROUPS
            </Link>
          </h5>
          <div className="artist-discovery-list">
            {artists &&
              artists
                .filter((a) => a.is_idol == true && a.is_group == true)
                .filter((a) => a.gender == "C")
                .map((artist) => (
                  <div key={artist._id} className="artist-link">
                    <Link
                      key={artist._id}
                      to={`/artist/${encodeId(artist.melon_artist_id)}`}
                    >
                      <p key={artist._id}>{artist.english_name}</p>
                    </Link>
                  </div>
                ))}
          </div>

          <br></br>
          <br></br>
          <h3>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: false }}
            >
              idol solos
            </Link>
          </h3>

          <h5>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: false, gender: "F" }}
            >
              FEMALE SOLOS
            </Link>
          </h5>
          <div className="artist-discovery-list">
            {artists &&
              artists
                .filter((a) => a.is_idol == true && a.is_group == false)
                .filter((a) => a.gender == "F")
                .map((artist) => (
                  <div key={artist._id} className="artist-link">
                    <Link
                      key={artist._id}
                      to={`/artist/${encodeId(artist.melon_artist_id)}`}
                    >
                      <p key={artist._id}>{artist.english_name}</p>
                    </Link>
                  </div>
                ))}
          </div>
          <br></br>
          <h5>
            <Link
              to="/artist-discovery/idol-groups"
              state={{ is_idol: true, is_group: false, gender: "M" }}
            >
              MALE SOLOS
            </Link>
          </h5>
          <div className="artist-discovery-list">
            {artists &&
              artists
                .filter((a) => a.is_idol == true && a.is_group == false)
                .filter((a) => a.gender == "M")
                .map((artist) => (
                  <div key={artist._id} className="artist-link">
                    <Link
                      key={artist._id}
                      to={`/artist/${encodeId(artist.melon_artist_id)}`}
                    >
                      <p key={artist._id}>{artist.english_name}</p>
                    </Link>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistDiscoveryListPage;
