import { Link } from "react-router-dom";
import { TruncateName } from "../HelperMethods";
import { encodeId } from "../HelperMethods";
import React from "react";

interface AllAlbumsDetailsProps {
  album: Album;
}

const AllAlbumsDetails: React.FC<AllAlbumsDetailsProps> = ({ album }) => {
  const id = album.melon_album_id;
  const album_year = String(album.release_date).substring(0, 4);

  return (
    <div className="album-list">
      <h6>{album_year}</h6>
      <Link to={`/album/${encodeId(id)}`} state={{ id: id }}>
        <p>
          <strong>{TruncateName(album.english_name)}</strong>
        </p>
        <img
          className="album-display-photo"
          src={album.picture_url}
          alt={"album photo of " + album.english_name}
          onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
            event.currentTarget.src = "/dubuFace260.jpg";
            event.currentTarget.onerror = null;
          }}
        />
      </Link>
    </div>
  );
};

export default AllAlbumsDetails;
