import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AllAlbumsDetails from "../../components/ViewAll/AllAlbumsIconDetails";
import ArtistHeaderDetails from "../../components/ViewAll/ArtistHeaderDetails";
import PageNotFoundPage from "../PageNotFoundPage";
import { Helmet } from "react-helmet";

type ComparisonKey = "oldest" | "newest" | "alphabetical";

const comparisons: Record<ComparisonKey, (a: Album, b: Album) => number> = {
  oldest: (a, b) =>
    new Date(a.release_date).getTime() - new Date(b.release_date).getTime(),
  newest: (a, b) =>
    new Date(b.release_date).getTime() - new Date(a.release_date).getTime(),
  alphabetical: (a, b) => a.english_name.localeCompare(b.english_name),
};

function ArtistAlbumsPage() {
  let { state } = useLocation();
  const [albums, setAlbums] = useState<Album[]>([]);
  const [artists, setArtist] = useState<Artist[] | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<ComparisonKey>("newest");
  const [error, setError] = useState<string | null>(null);

  let artist_id;
  if (!state) {
    artist_id = Number(window.location.href.split("/").slice(-2)[0]);
  } else {
    artist_id = state.id;
  }

  useEffect(() => {
    const fetchAlbums = async () => {
      const response = await fetch("/api/albums/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        json.sort(
          (a: { release_date: string }, b: { release_date: string }) =>
            new Date(b.release_date).getTime() -
            new Date(a.release_date).getTime()
        );
        setAlbums(json);
      } else if (response.status == 404 && !error) {
        setError("Album does not exist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const fetchArtist = async () => {
      const response = await fetch("/api/artists/" + artist_id);
      const json = await response.json();

      if (response.ok) {
        setArtist(json);
      } else if (response.status == 404 && !error) {
        setError("Artist does not exist");
      } else {
        setError(response.status + " - " + json.error);
      }
    };

    const renderSortedResults = () => {
      console.log("renderfilteredresults called");
      const sortedAlbums: Album[] = albums;
      if (selectedOrder === "oldest") {
        // console.log('here');
        sortedAlbums.sort(
          (a, b) =>
            new Date(a.release_date).getTime() -
            new Date(b.release_date).getTime()
        );
        setAlbums(sortedAlbums);
      } else if (selectedOrder === "alphabetical") {
        // console.log('here1');
        sortedAlbums.sort((a, b) =>
          a.english_name.localeCompare(b.english_name)
        );
        setAlbums(sortedAlbums);
      } else {
        // console.log('here2');
        sortedAlbums.sort(
          (a, b) =>
            new Date(b.release_date).getTime() -
            new Date(a.release_date).getTime()
        );
        setAlbums(sortedAlbums);
      }
    };

    fetchAlbums();
    fetchArtist();
    if (albums) {
      console.log(selectedOrder);
      renderSortedResults();
    }

    // renderFilteredResults();
  }, []);
  if (error) return <PageNotFoundPage msg={error} />;
  return (
    <div className="padding">
      <div className="artist-album-details">
        <Helmet>
          {artists && <title>{artists[0].english_name} albums - dubu</title>}
          {!artists && <title>loading albums - dubu</title>}
          {artists && (
            <meta
              name="keywords"
              content={
                "kpop, dubu, database, kpop credits, " + artists[0].english_name
              }
            />
          )}
          {!artists && (
            <meta
              name="keywords"
              content={"kpop, dubu, database, kpop credits"}
            />
          )}
          {artists && (
            <meta
              name="description"
              content={
                artists[0].english_name + " All Albums: Discover discography"
              }
            />
          )}
        </Helmet>
        {artists && (
          <ArtistHeaderDetails artist={artists && artists[0]} type="albums" />
        )}
        <div className="album-header">
          <h4 className="album-name">
            {" "}
            {albums && albums.length > 0 && "albums (" + albums.length + ")"}
          </h4>
          <select
            value={selectedOrder}
            onChange={(e) => {
              const order = e.target.value as ComparisonKey;
              setSelectedOrder(order);
              setAlbums(albums.sort(comparisons[order]));
            }}
          >
            <option value="newest">newest to oldest</option>
            <option value="oldest">oldest to newest</option>
            <option value="alphabetical">alphabetical</option>
          </select>
        </div>
        <div className="display-list">
          {albums &&
            albums.map((album) => (
              <AllAlbumsDetails key={album._id} album={album} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default ArtistAlbumsPage;
