import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { Link } from "react-router-dom";
import SongList from "./SongList";
import PhotoMethods from "./HelperMethods";
import { useState } from "react";
// import { ReactComponent as ShareIcon } from "../images/arrow-up-from-bracket-solid.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { encodeId } from "./HelperMethods";

const AlbumDetails: React.FC<AlbumDetailsProps> = ({ album, infos, songs }) => {
  const releaseDate = album.release_date.slice(0, 10);
  const tooltip = <Tooltip id="tooltip">copy album link</Tooltip>;
  const copied_tooltip = <Tooltip id="tooltip">copied album url!</Tooltip>;
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowCopiedTooltip(true);
    setTimeout(() => {
      setShowCopiedTooltip(false);
    }, 1000);
  };

  return (
    <div className="album-details">
      <div className="artist-photo-wrapper">
        <img
          className="main-photo"
          src={PhotoMethods(album.picture_url)}
          alt={`album photo of ${album.english_name}`}
          onError={(event: React.SyntheticEvent<HTMLImageElement>) => {
            event.currentTarget.src = "/dubuFace.jpg";
            event.currentTarget.onerror = null;
          }}
        />
      </div>
      <div className="album-right-card">
        <h5>
          💿 {album.album_type?.toUpperCase()} • {releaseDate}
        </h5>
        <h1 className="main-text">
          {album.english_name}
          <OverlayTrigger
            placement="bottom"
            overlay={showCopiedTooltip ? copied_tooltip : tooltip}
            trigger="hover"
            rootClose
          >
            <button
              className="share-icon"
              onClick={copyLink}
              style={{ paddingLeft: "1em" }}
            >
              {/* <ShareIcon style={{ display: "flex", width: ".4em" }} /> */}
            </button>
          </OverlayTrigger>
        </h1>
        {album.korean_name && <h4>{album.korean_name}</h4>}
        {infos &&
          infos.map((info) => (
            <h3 key={info.english_name}>
              <Link
                to={`/artist/${encodeId(info.melon_artist_id)}`}
                state={{ id: info.melon_artist_id }}
              >
                {info.melon_artist_id !== -1 && info.english_name}
              </Link>
              {info.melon_artist_id === -1 && info.english_name}
            </h3>
          ))}

        <br />

        <h4>tracklist</h4>
        {songs && songs.map((song) => <SongList key={song._id} song={song} />)}
      </div>
    </div>
  );
};

export default AlbumDetails;
